import {createSlice} from '@reduxjs/toolkit';
export const reviewSlice = createSlice({
    name :'review',
    initialState :{
        data  : {
            storeType: 1,
            rating: 0,
            store: {
                label : "All",
                value : 0
            }
          }
      
    },
    reducers : {
        review:(state,action)=>{
            state.data = action.payload;
        }
    }
})
export const {review} = reviewSlice.actions;
export default reviewSlice.reducer;