import {createSlice} from '@reduxjs/toolkit';
export const menuSlice = createSlice({
    name :'Menu',
    initialState : {
        tab : 'dashboard'
    },
    reducers : {
        setActiveTab:(state,action)=>{
            console.log({action});
            state.tab = action.payload;
        }
    }
})
export const {setActiveTab} = menuSlice.actions;
export default menuSlice.reducer;